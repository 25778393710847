<template>
  <footer class="text-gray-600 body-font mb-0 pb-0 bg-white">
    <div
      class="container px-14 py-3 mx-auto flex items-center sm:flex-row flex-col"
    >
      <a
        class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
      >
        <svg
          viewBox="0 0 305 62"
          class="text-slate-900 dark:text-white w-auto h-5"
        >
          <g fill="#38bdf8">
            <path
              d="m26.2 35.4-20 12.5v4.2l20 12.5 2.6-4.2L12.2 50l16.6-10.4zM71.2 39.6 87.8 50 71.2 60.4l2.6 4.2 20-12.5v-4.2l-20-12.5zM51.7 28.7 39 55.9l13.6 6.3 1-2.2 11.6-25 3.2-6.8-13.6-6.3zM38.4 74.9l12.1-8.1-13.6-6.4z"
              transform="translate(-6 -16)"
            />
          </g>
          <path
            d="M125.69 7.361c0 .31.918 3.266 2.75 8.873s3.836 11.74 6.006 18.403 5.087 15.471 8.756 26.424h3.332L126 0h-.62L105 61.06h3.177zm-1.395 36.343h-2.79l4.65 17.357h2.79zm34.947-6.045V0h-2.634v61.062h2.634V37.66zm1.085 2.48h22.627v20.922h2.635V16.04h-2.635v21.62h-22.627v2.48zm70.515 20.922V0h-2.557l-15.033 27.973-9.996-18.597-2.247 1.24 12.243 22.704 15.11-28.206v55.947h2.48zm-32.7 0V5.27l.62 1.162 2.247-1.162-2.867-5.27h-2.48v61.062h2.48zm44.944-22.627V2.17h18.597V0h-20.767v38.435h2.17zm19.372 22.627V58.97h-19.372v-16.04h3.1v-2.092h-5.27V61.06h21.542zm17.048 0c2.274 0 4.39-.26 6.354-.775l-.62-2.324c-1.755.465-3.67.697-5.734.697h-4.572V2.634h4.572a21.58 21.58 0 0 1 16.544 7.44c2.041 2.324 3.642 5.125 4.804 8.407s1.743 6.885 1.743 10.81v1.627c0 2.894-.387 5.734-1.162 8.524s-1.832 5.358-3.177 7.71-2.917 4.405-4.727 6.16-3.72 3.022-5.734 3.797l.93 2.247c2.274-.93 4.39-2.336 6.354-4.223s3.68-4.095 5.153-6.625 2.635-5.308 3.487-8.33 1.279-6.11 1.279-9.26V29.29c0-4.235-.647-8.125-1.938-11.662s-3.072-6.599-5.346-9.183-4.96-4.599-8.06-6.044-6.481-2.17-10.15-2.17h-6.974v60.83h6.974z"
            fill="currentColor"
          />
        </svg>
      </a>
      <p
        class="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4"
      >
        © 2020 Tailblocks —
        <a
          href="https://twitter.com/knyttneve"
          class="text-gray-600 ml-1"
          rel="noopener noreferrer"
          target="_blank"
          >@knyttneve</a
        >
      </p>
      <span
        class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start"
      >
        <a class="text-gray-500">
          <svg
            fill="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            class="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <path
              d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"
            ></path>
          </svg>
        </a>
        <a class="ml-3 text-gray-500">
          <svg
            fill="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            class="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <path
              d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"
            ></path>
          </svg>
        </a>
        <a class="ml-3 text-gray-500">
          <svg
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            class="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
            <path
              d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"
            ></path>
          </svg>
        </a>
        <a class="ml-3 text-gray-500">
          <svg
            fill="currentColor"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0"
            class="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <path
              stroke="none"
              d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
            ></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
        </a>
      </span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped></style>
